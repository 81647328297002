<template>
  <section class="public-contract">
    <h1 class="title">Публічний договір (ОФЕРТА)</h1>

    <div class="container container--min">
      <article>
        <h4 class="hidden-text">Положення Публічного договору</h4>

        <p class="paragraph">
          Цей документ (далі – «Оферта») є офіційною, публічною пропозицією ТОВ «АЛОКА АС» (далі – «Виконавець»)
          укласти договір (далі – «Договір») на вказаних нижче умовах. ТОВ «АЛОКА АС» (ЄДРПОУ 43788167).
        </p>

        <p class="paragraph">
          Реквізити безпосереднього постачальника послуг (сторони укладеного договору) фіксуються у виставленому рахунку,
          електронному інвойсі або чеку. Пропозиція до підписання Договору адресована необмеженому колу фізичних
          і юридичних осіб (далі – «Замовник»). Стосунки сторін регулюються цим Договором і положеннями законодавства
          України.
        </p>
      </article>

      <article>
        <h4 class="subtitle">1. ПРЕДМЕТ ДОГОВОРУ</h4>

        <p class="paragraph">
          1.1. На умовах цього Договору Виконавець зобов'язується надати Замовнику інформаційні та консалтингові
          послуги в рамках надання доступу до сервісу і продуктів, перелік та опис яких зазначено на сайті
          <a class="link" href="https://biz.ntz.digital">https://biz.ntz.digital</a>
          (далі – «Тариф»).
        </p>
      </article>

      <article>
        <h4 class="subtitle">2. ПОРЯДОК АКЦЕПТУ ОФЕРТИ</h4>

        <p class="paragraph">
          2.1. Надання тексту даної оферти Замовникові, так само як і розміщення тексту оферти на сайті
          <a class="link" href="https://biz.ntz.digital">https://biz.ntz.digital</a>
          є пропозицією Виконавця укласти цей договір на вказаних умовах.
        </p>

        <p class="paragraph">
          2.2. Цей Договір укладається на умовах договору приєднання (ст. 634 Цивільного кодексу України) та вважається
          укладеним з моменту прийняття публічної оферти. Оферта може бути акцептована (прийнята) будь-якою фізичною
          або юридичною особою, що має зацікавленість в отриманні послуг, що надаються Виконавцем.
        </p>

        <p class="paragraph">
          2.3. Приймаючи публічну оферту на укладення такого Договору, Користувач підтверджує,
          що він повністю приймає його умови.
        </p>

        <p class="paragraph">
          2.4. Користувач приймає (акцептує) оферту шляхом: здійснення конклюдентних дій – оплати вибраного Курсу
          на умовах, зазначених у цьому Договорі. Письмова форма цього Договору згідно ЗУ «Про електронну комерцію»
          додатково може підтверджуватися направленням електронного листа.
        </p>

        <p class="paragraph">
          2.5. Акцепт даної Оферти означає, що Замовник повністю приймає всі умови, що містяться в Оферті в цілому
          (тобто в повному обсязі і без виключень).
        </p>
      </article>

      <article>
        <h4 class="subtitle">3. УМОВИ НАДАННЯ ПОСЛУГ</h4>

        <p class="paragraph">
          3.1. Опис і перелік послуг, що надаються, програма та терміни надання доступу до Тарифу, а також інші умови
          опубліковані на сайті
          <a class="link" href="https://biz.ntz.digital">https://biz.ntz.digital</a>.
        </p>

        <p class="paragraph">
          3.2. Послуги, що становлять предмет цього Договору, надаються Виконавцем шляхом забезпечення навчальних
          тренажерних центрів єдиною системою прийому, обробки і опрацювання заявок на навчання; спрощеною системою
          опрацювання документів.
        </p>

        <p class="paragraph">
          3.3. Термін надання послуг визначається умовами співпраці та вказаний на сайті
          <a class="link" href="https://biz.ntz.digital">https://biz.ntz.digital</a>.
        </p>
      </article>

      <article>
        <h4 class="subtitle">4. СПОСІБ ОПЛАТИ</h4>

        <p class="paragraph">
          4.1. Ціна визначається та застосовується відповідно до обраного Замовником Тарифу, яка вказується на сайті
          <a class="link" href="https://biz.ntz.digital">https://biz.ntz.digital</a>.
        </p>

        <p class="paragraph">
          4.2. Розрахунки за послуги здійснюються шляхом перерахування грошей на рахунок Виконавця чи через онлайн
          платіжні системи, що використовуються Виконавцем.
        </p>

        <p class="paragraph">
          4.3. Оплата послуг здійснюється у гривневому еквіваленті згідно встановленого Національним банком України
          курсу валют станом на день оплати.
        </p>

        <p class="paragraph">
          4.4. Після повної або часткової оплати кошти повертаються визначеним порядком повернення товарів або відмови
          від послуг та повернення коштів.
        </p>

        <p class="paragraph">
          4.5. Замовник не може вимагати зниження вартості послуг чи повернення коштів, у випадку, якщо він не
          скористався послугами. Моментом виконання надання послуг вважається початок використання ресурсів Веб-сайту
          після укладення договору.
        </p>

        <p class="paragraph">
          4.6. Послуги вважаються сплаченими у момент надходження грошових коштів на розрахунковий рахунок Виконавця.
          Виконавець інформує Замовника про надходження платежу.
        </p>
      </article>

      <article>
        <h4 class="subtitle">5. ПРАВА ТА ОБОВ'ЯЗКИ ЗАМОВНИКА</h4>

        <p class="paragraph">
          5.1. Замовник має право особистої використовувати єдину систему приймання, обробки і опрацювання заявок
          на навчання; спрощеною системою опрацювання документів.
        </p>

        <p class="paragraph">
          5.2. Замовник має право отримувати та використовувати ресурси Веб-сайту, які надаються в межах
          надання послуг Виконавцем.
        </p>

        <p class="paragraph">
          5.3. Замовник зобов'язаний прийняти умови використання продукту, які опубліковані на сайті та дотримуватися
          правил його використання.
        </p>

        <p class="paragraph">
          5.4. Усі права на веб-сайт та інформаційні матеріали належать Виконавцеві. Права інтелектуальної власності на
          сервіси та інформаційні матеріали, використані у межах Тарифу, зокрема, але не виключно, зображення, відео,
          логотипи, графіка, звуки, належать Виконавцю. Отримані Замовником в результаті надання послуг за цим Договором
          інформаційні матеріали або їх частини підлягають наступним обмеженням: їх використання обмежене зобов'язанням
          Замовника не відтворювати, не повторювати і не копіювати, не продавати, і не поступатися, а також
          не використовувати матеріали
        </p>

        <p class="paragraph">
          Для будь-яких інших цілей, а також не передавати доступ до отриманих матеріалів третім особам.
          Замовник має право у будь-який час відмовитися від надання матеріалів.
        </p>

        <p class="paragraph">
          5.5. Замовник має право в будь-який час отримати інформацію про здійсненні платежі і стан взаєморозрахунків.
        </p>

        <p class="paragraph">
          5.6. Укладанням Договору на умовах даної Оферти Замовник надає Виконавцеві право: а) збирати, зберігати і
          обробляти, та знищувати персональні дані Замовника; б) відправляти Замовникові повідомлення, відправляти
          email і SMS.
        </p>
      </article>

      <article>
        <h4 class="subtitle">6. ПРАВА ТА ОБОВ'ЯЗКИ ВИКОНАВЦЯ</h4>

        <p class="paragraph">
          6.1. Виконавець зобов'язаний надавати послуги на умовах даної Оферти.
        </p>

        <p class="paragraph">
          6.2. Виконавець має право призупинити доступ до матеріалів і сервісів або відмовити Замовникові у використанні
          продукту у разі порушення Замовником умов даної Оферти, а також встановлення спроби втручання в роботу сайту і
          доступних сервісів (або отримання до них доступу, в обхід інструкцій Виконавця).
        </p>

        <p class="paragraph">
          6.3. Виконавець залишає за собою право у будь-який момент доповнювати, переміщати, змінювати, видаляти
          матеріали та сервіси, розміщені на сайті.
        </p>

        <p class="paragraph">
          6.4. Виконавець залишає за собою право вносити зміни в умови надання Послуг, терміни надання Послуг та
          до самого продукту у будь-який момент і на свій розсуд. В разі внесення змін до умов участі, такі зміни
          набирають чинності з моменту розміщення змінених умов участі на сайті (якщо термін вступу змін в силу
          не визначений додатково) і вважається прийнятим Замовником з моменту першої оплати
        </p>

        <p class="paragraph">
          Послуг, здійсненої після зміни умов. У будь-якому випадку, передплачений обсяг послуг не може бути змінений
          в меншу сторону.
        </p>
      </article>

      <article>
        <h4 class="subtitle">7. ВІДПОВІДАЛЬНІСТЬ СТОРІН</h4>

        <p class="paragraph">
          7.1. У випадку порушення зобов'язань, надання недостовірної інформації, при укладанні або в процесі виконання
          Договору, сторони несуть відповідальність, встановлену чинним законодавством.
        </p>

        <p class="paragraph">
          7.2. Виконавець не несе відповідальність за: - неможливість надання послуг з причин від нього не залежних,
          включаючи форс-мажор, порушення роботи ліній зв'язку, несправність устаткування і програмного забезпечення,
          що не належить Виконавцеві; - порушення безпеки устаткування і програмного забезпечення, які використовує
          Замовник для отримання послуг; - втрату конфіденційної інформації або її частини, якщо в цьому немає провини
          Виконавця; - які-небудь збитки третіх осіб, що виникли не з вини Виконавця.
        </p>

        <p class="paragraph">
          7.3. Замовник несе відповідальність за: - використання третіми особами облікових даних, які використовуються
          для отримання доступу до Послуг; - використання матеріалів, які розміщені на сайті або, які надаються в процесі
          надання Послуг, з метою їх подальшого перепродажу, розповсюдженню або передачі третім особам; - порушення умов
          використання послуг; - порушення умов платежу; - втручання в роботу сайту і доступних сервісів або спробу
          отримати до них доступ в обхід інструкцій Виконавця.
        </p>

        <p class="paragraph">
          7.4. Обмеження відповідальності Виконавця: - сукупна відповідальність Виконавця по будь-якому позову або
          претензії обмежується сумою придбаної послуги; - в разі порушення Виконавцем термінів надання послуг,
          відповідальність Виконавця обмежується виключно продовженням термінів надання послуг або наданням послуг в
          нові терміни до повного виконання зобов'язань.
        </p>

        <p class="paragraph">
          7.5. У випадку передачі Замовником інформаційних матеріалів третім особам без письмової згоди Виконавця,
          так само як і виявлення фактів використання Замовником отриманих в процесі використання продукту інформаційних
          матеріалів в комерційних цілях (з метою введення аналогічної діяльності), Замовник несе наступну
          відповідальність: - Замовник може бути усунений від використання ресурсу, а договір може бути розірваний
          Виконавцем в однобічному порядку, без повернення вартості (у такому разі, різниця між вартістю фактично
          отриманих послуг і сумою внесеної
        </p>

        <p class="paragraph">
          Передоплати утримується Виконавцем як компенсація збитку, заподіяного таким порушенням); - Замовник відшкодовує
          Виконавцеві всі прямі та непрямі збитки, заподіяні таким порушенням; - Замовник несе відповідальність
          за порушення авторських і майнових прав Виконавця, встановлену законодавством України.
        </p>

        <p class="paragraph">
          7.6. У випадку виявлення Виконавцем фактів використання Замовником техніки, методики і прийомів продукту
          Виконавця (які сталі відомі Замовникові в процесі отримання послуг за Договором) з метою ведення аналогічної
          діяльності у сфері прийому, обробки і опрацювання заявок; опрацюванні документів, Замовник сплачує на користь
          Виконавця штраф в двадцятикратному розмірі від вартості придбаних Послуг (вартості тарифу участі),
          за кожен випадок порушення.
        </p>
      </article>

      <article>
        <h4 class="subtitle">8. ТЕРМІН ДІЇ ДОГОВОРУ</h4>

        <p class="paragraph">
          8.1. Договір набирає чинності з моменту акцепту оферти і діє до повного виконання сторонами зобов'язань.
          Договір може бути розірваний достроково, у випадках, прямо встановлених Офертою і чинним законодавством.
        </p>
      </article>

      <article>
        <h4 class="subtitle">9. СПОРИ І ПОРЯДОК ЇХ ВИРІШЕННЯ</h4>

        <p class="paragraph">
          9.1. Всі спори, що виникають при виконанні цього Договору, вирішуються сторонами шляхом переговорів. У випадку
          якщо спори не врегульовані шляхом переговорів, вони вирішуються відповідно до чинного законодавства України.
        </p>
      </article>

      <article>
        <h4 class="subtitle">10. УГОДА ПРО ЕЛЕКТРОННИЙ ДОКУМЕНТООБІГ</h4>

        <p class="paragraph">
          10.1. Сторони визнають юридичну силу документів, що складені в електронній формі (заявок, Актів, повідомлень,
          рахунків, угод, листів і ін.), направляються електронною поштою на вказані в договорі реквізити, якщо можливо
          достовірно встановити, що документ виходитиме від сторони договору.
        </p>

        <p class="paragraph">
          10.2. Сторони прийшли до згоди керуватися умовами про електронний документообіг і електронну взаємодію,
          викладеним нижче: A. Заявки, повідомлення, рахунки складаються письмово у формі електронного документа і не
          оформляються на паперових носіях. B. Відповідно до угоди про електронний документообіг Сторони використовують
          прості електронні підписи, підписом для кожної із Сторін є унікальна адреса електронної пошти
          (логін і пароль облікового запису поштового сервісу). C. Сторони виходять з того, що електронне повідомлення,
          направлене з електронної
        </p>

        <p class="paragraph">
          Поштової адреси Сторони, виражає дійсне волевиявлення Сторони і намір укласти, змінити або припинити
          операцію – відповідно до значення тієї або іншої дії, визначеної договором. D. Перевірка справжності та
          достовірності повідомлення, що виходить від Сторони, здійснюється шляхом звірення адреси відправника з
          адресою, вказаним Замовником або Виконавцем в цьому договорі як контактний реквізит. E. Кожна із Сторін
          повинна забезпечити збереження даних свого облікового запису електронної пошти і недоступність їх третім
          особам. F. При необхідності, належним доказом
        </p>

        <p class="paragraph">
          Підписання відповідних документів є роздруківки електронних повідомлень, завірені стороною,
          що роздрукувала. G. В якості первинних облікових документів, що є підставою для розрахунків,
          приймаються рахунки і інші документи, складені в електронній формі.
        </p>
      </article>

      <article>
        <h4 class="subtitle">11. ІНШІ УМОВИ</h4>

        <p class="paragraph">
          11.1. Оферта набирає чинності з моменту публікації на сайті Виконавця і діє до моменту відкликання Оферти Виконавцем.
        </p>

        <p class="paragraph">
          11.2. Виконавець залишає за собою право вносити зміни в умови Оферти і відкликати Оферту у будь-який момент на
          свій розсуд. В разі внесення змін до Оферти, такі зміни набирають чинності з моменту публікації оферти в новій
          редакції оферти на сайті, якщо інший термін вступу змін в силу не визначений додатково.
        </p>

        <p class="paragraph">
          11.3. Якщо яка-небудь з умов Оферти стає недійсною або суперечить чинному законодавству, таке положення
          замінюється аналогічним, таким, що максимально відповідає початковим намірам Сторін, що містилися в Оферті.
          Недійсність окремих положень Оферти не тягне недійсності Оферти в цілому.
        </p>

        <p class="paragraph">
          11.4. Укладенням Договору на умовах даної Оферти Замовник дає згоду на обробку Виконавцем його персональних
          даних, а саме на здійснення, у тому числі, наступних дій: збір, систематизацію, накопичення, зберігання,
          уточнення (оновлення, зміна), використання, з метою надання інформаційних послуг і їх оплати. Вказані дії
          можуть здійснюватися з використанням засобів автоматизації.
        </p>

        <p class="paragraph">
          11.5. Виконавець має право передавати персональні дані Замовника, а також конфіденційну інформацію Замовника
          своїм співробітникам, а також третім особами, залученими Виконавцем до надання послуг по договору, виключно
          з метою організації надання послуг, забезпечуючи при цьому належне збереження конфіденційної інформації і
          персональних даних.
        </p>

        <p class="paragraph">
          11.6. По закінченню дії Договору Сторони забезпечують належне зберігання конфіденційної інформації та
          комерційної таємниці, яка стала їм відома в процесі виконання договірних зобов'язань, а також інших відомостей
          протягом терміну, передбаченого законодавством.
        </p>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PublicContract'
}
</script>

<style lang="scss" scoped>
$privacy: "public-contract";
.#{$privacy} {
  padding: 60px 0;

  article {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>